import { amgApi } from "@/service/axios";

class ExpensesService {
  async getExpenses(data) {
    let res = await amgApi.post("/expenses/get-expenses-bussiness", data);

    return res.data;
  }

  async createExpense(
    accountId,
    descriptionId,
    payableTo,
    payment,
    transaction,
    payMethod,
    payMethodId,
    creator,
    status,
    date_payment
  ) {
    let body = {
      account_id: accountId,
      id_description: descriptionId,
      pay_to: payableTo,
      payment: payment,
      transaction_id: transaction,
      payment_method: payMethod,
      id_credit_card: null,
      id_e_check: null,
      created_by: creator,
      module_id: 3,
      status_id: status,
      date_payment: date_payment,
    };
    if (payMethod == "card") {
      body.payment_method = 1;
      body.id_credit_card = payMethodId;
    } else {
      body.payment_method = 2;
      body.id_e_check = payMethodId;
    }
    let res = await amgApi.post("/expenses/insert-expenses-bussiness", body);

    return res.data;
  }

  async updateExpense(
    expenseId,
    accountId,
    descriptionId,
    pay_to,
    payment,
    transaction,
    payMethod,
    payId,
    updater,
    statusId,
    date_payment
  ) {
    let body = {
      expense_id: expenseId,
      account_id: accountId,
      id_description: descriptionId,
      pay_to,
      payment,
      transaction_id: transaction,
      payment_method: payMethod,
      updated_by: updater,
      module_id: 3,
      status_id: statusId,
      date_payment: date_payment,
    };
    if (payMethod == "card") {
      body.payment_method = 1;
      body.id_credit_card = payId;
    } else {
      body.payment_method = 2;
      body.id_e_check = payId;
    }
    let res = await amgApi.post("/expenses/update-expenses-bussiness", body);

    return res.data;
  }

  async deleteExpense(id, deletor) {
    let body = {
      deleted_by: deletor,
      expense_id: id,
    };
    let res = await amgApi.post("/expenses/delete-expenses-bussiness", body);

    return res.data;
  }

  async getTrackingExpense(id) {
    let body = {
      expense_id: id,
    };
    let res = await amgApi.post(
      "/expenses/get-tracking-expenses-bussiness",
      body
    );

    return res.data;
  }

  async getPermissions(body) {
    let res = await amgApi.post("/expenses/get-password", body);
    return res.data > 0;
  }

  async getExpensesUser(account) {
    let body = {
      name_account: account,
      program_id: 1,
    };
    try {
      const data = await amgApi.post("/expenses/search-account-expenses", body);
      return data.data;
    } catch (error) {
      console.log("Error expensesearchaccount service");
    }
  }

  async getCards(deleted = false) {
    let body = { module_id: 3, is_deleted: deleted ? 1 : 0 };
    let res = await amgApi.post(
      "/expenses/get-all-cards-expenses-by-module",
      body
    );

    return res.data;
  }

  async createCard(expMont, expYear, number, cvv, name, user) {
    let body = {
      exp_month: expMont,
      exp_year: expYear,
      card_number: number,
      card_cvv: cvv,
      card_name: name,
      user_id: user,
      module_id: 3,
    };
    let res = await amgApi.post("/expenses/insert-card-expenses", body);

    return res.data;
  }

  async deleteCard(id, deletor) {
    let body = {
      ccard_id: id,
      deleted_by: deletor,
    };
    let res = await amgApi.post("/expenses/delete-cards-expenses", body);

    return res.data;
  }

  async observeCard(id, observer) {
    let body = {
      ccard_id: id,
      observed_by: observer,
    };

    let res = await amgApi.post("/expenses/observe-cards-expenses", body);

    return res.data;
  }

  async getTrackingCard(id) {
    let body = {
      card_id: id,
    };
    let res = await amgApi.post("/expenses/get-tracking-cards-expenses", body);

    return res.data;
  }

  async createECheck(account, client, routing, address, creator) {
    let body = {
      account_name: account,
      routing_name: routing,
      username: client,
      address: address,
      created_by: creator,
      module_id: 3,
    };
    let res = await amgApi.post("/expenses/insert-e-checks-expenses", body);

    return res.data;
  }

  async createDescription(name) {
    let body = {
      description: name,
      module_id: 3,
    };

    let res = await amgApi.post("/expenses/insert-description-expenses", body);

    if (res.data.code == 1) throw new Error("Description name already exist");

    return res.data;
  }

  async createPayableTo(name) {
    let body = {
      description: name,
      module_id: 3,
    };

    let res = await amgApi.post("/expenses/insert-payable-to-expenses", body);

    if (res.data.code == 1) throw new Error("Payable to name already exist");

    return res.data;
  }

  async getChecks(deleted = false) {
    let body = { module_id: 3, is_deleted: deleted ? 1 : 0 };
    let res = await amgApi.post(
      "/expenses/get-all-checks-expenses-by-module",
      body
    );

    return res.data;
  }

  async deleteCheck(id, deletor) {
    let body = {
      echeck_id: id,
      deleted_by: deletor,
    };
    let res = await amgApi.post("/expenses/delete-e-checks-expenses", body);

    return res.data;
  }

  async observeCheck(id, observer) {
    let body = {
      echeck_id: id,
      observed_by: observer,
    };

    let res = await amgApi.post("/expenses/observe-e-checks-expenses", body);

    return res.data;
  }

  async getTrackingCheck(id) {
    let body = {
      check_id: id,
    };
    let res = await amgApi.post("/expenses/get-tracking-checks-expenses", body);

    return res.data;
  }

  async getAdvisors(userId, userRole) {
    let body = {
      idmodule: 3,
      iduser: userId,
      idrole: userRole,
    };
    const data = await amgApi.post("/commons/users-programs", body);

    return data.data;
  }

  async getDescriptions() {
    let body = {
      module_id: 3,
    };
    let res = await amgApi.post("/expenses/get-descriptions-expenses", body);

    return res.data;
  }

  async getPayables() {
    let body = {
      module_id: 3,
    };
    let res = await amgApi.post("/expenses/get-payables-expenses", body);

    return res.data;
  }

  async getStatus() {
    let res = await amgApi.post("/expenses/get-status-expenses-bussiness");

    return res.data;
  }

  async changePassword(newPass) {
    let body = {
      pass: newPass,
      module_id: 3,
    };
    let res = await amgApi.post("/expenses/change-password-expenses", body);

    return res;
  }
}

export default new ExpensesService();
