<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          @click="openModalCreateAndEditEntrances"
          v-if="isInEntrancesTab"
          variant="primary"
        >
          Create Entrance
        </b-button>
      </template>
    </header-slot>
    <div v-if="isValidPassword">
      <b-nav card-header pills class="m-0">
        <b-nav-item
          v-for="(item, index) in tabs"
          :key="index"
          :to="{ name: item.route }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :id="'abcd_' + index"
          :class="
            item.name == 'ENTRANCES' && $route.meta.type == 'entrances'
              ? 'bg_primary'
              : ''
          "
        >
          {{ item.name }}
        </b-nav-item>
      </b-nav>

      <b-card
        no-body
        class="border-top-primary border-3 border-table-radius px-0"
      >
        <modal-create-and-edit-entrances
          v-if="modalCreateAndEditEntrancesOpened"
          :operationType="createOperation"
          no-close-on-esc="false"
          o-close-on-backdrop="false"
          @reload="updateEntrancesTable"
          @close="closeCreateAndEditEntrances"
        />
        <router-view :key="$route.name" />
      </b-card>
    </div>
    <div v-else>
      <module-login-validation />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModuleLoginValidation from "@/views/business/views/settings/components/ModuleLoginValidation.vue";

export default {
  components: {
    ModuleLoginValidation,
    ModalCreateAndEditEntrances: () =>
      import("./components/ModalCreateAndEditEntrances.vue"),
  },
  data() {
    return {
      createOperation: 1,
      modalCreateAndEditEntrancesOpened: false,
      tabs: [
        {
          route: "settings-accesibility",
          name: "ACCESIBILITY",
        },
        {
          route: "settings-entrances",
          name: "ENTRANCES",
        },
        {
          route: "settings-settings",
          name: "GREETINGS",
        },
        {
          route: "first-note-settings",
          name: "FIRST NOTE",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      isValidPassword: "BusinessSettings/G_ISVALID_PASSWORD",
    }),
    isInEntrancesTab() {
      return this.$route.meta.tab === 2;
    },
    isRolePermitted() {
      return (
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.eng_soft
      );
    },
  },
  methods: {
    closeCreateAndEditEntrances() {
      this.modalCreateAndEditEntrancesOpened = false;
    },
    openModalCreateAndEditEntrances() {
      this.modalCreateAndEditEntrancesOpened = true;
    },
    updateEntrancesTable() {
      this.$refs.entrancesListTable.refresh();
    },
    ...mapActions({
      setValidPassword: "BusinessSettings/A_UPDATE_VALID_PASSWORD",
    }),
    requestPassword() {
      if (this.isRolePermitted) this.setValidPassword(true);
    },
  },
  created() {
    this.requestPassword();
  },
  beforeDestroy() {
    !this.isRolePermitted && this.setValidPassword(false);
  },
};
</script>
<style>
.bg_primary a {
  background: #0090e7;
  color: #fff !important;
}

.insert-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.img-fluid {
  max-width: 50%;
  height: auto;
}
.label-class {
  font-size: 1rem;
  font-weight: bold;
}
</style>